
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue, Options } from "vue-class-component";
import {
  ApiEndpoint,
  CloudStorage,
  FrontendClientResponse,
  OauthClient,
} from "../../types/index";

@Options({
  // Define component options here
  // props: {
  //   ...
  // },
})
class FrontendClientComponent extends Vue {
  frontendClients: FrontendClientResponse[] = [];
  loading: boolean = true;
  error: string = "";
  created() {
    this.getFrontendClients().then(() => {
      this.loading = false;
    });
  }

  formatApiEndpointDomain(apiEndpoint: ApiEndpoint): string {
    return apiEndpoint?.domain ?? "";
  }

  formatOauthClientIdAndSecret(oauthClient: OauthClient): string {
    if (oauthClient?.client_id && oauthClient?.secret) {
      return `id: ${oauthClient.client_id} & secret: ${oauthClient.secret}`;
    } else {
      return "";
    }
  }

  formatCloudStoragePath(cloudStorage: CloudStorage): string {
    if (cloudStorage?.path && cloudStorage?.prefix && cloudStorage?.version) {
      return `${cloudStorage.path}${cloudStorage.prefix}${cloudStorage.version}`;
    } else {
      return "";
    }
  }

  async getFrontendClients() {
    try {
      const response: AxiosResponse<{ data: FrontendClientResponse[] }> =
        await apiFetch.frontendClient.getFrontendClientsList();
      this.frontendClients = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async deleteFrontendClient(id: number) {
    try {
      await apiFetch.frontendClient.deleteFrontendClient(id);
      await this.getFrontendClients();
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default FrontendClientComponent;
