<template>
  <div class="home">
    <h2>Frontend Client List View Page</h2>
    <div v-if="error" class="err-msg">{{ error }}</div>
    <div v-if="loading">Loading...</div>
    <div v-else class="list-view-nav">
      <router-link class="custom-btn" to="/frontend-client/create">
        Create
      </router-link>
    </div>
    <table class="list-view">
      <tr>
        <th>Frontend Client Domain</th>
        <th>API Endpoint Domain</th>
        <th>OAuth Client</th>
        <th>Cloud Storage Path</th>
        <th>Local Container Name</th>
        <th>SSR Service Domain</th>
        <th>Actions</th>
      </tr>
      <tr v-for="frontendClient in frontendClients" :key="frontendClient.id">
        <td>{{ frontendClient.domain }}</td>
        <td>{{ formatApiEndpointDomain(frontendClient.api_endpoint) }}</td>
        <td>{{ formatOauthClientIdAndSecret(frontendClient.oauth_client) }}</td>
        <td>{{ formatCloudStoragePath(frontendClient.cloud_storage) }}</td>
        <td>{{ frontendClient.local_container_name }}</td>
        <td>{{ frontendClient.ssr_service_domain }}</td>
        <td>
          <router-link
            class="table-btn"
            :to="`/frontend-client/${frontendClient.id}`"
          >
            Edit
          </router-link>
          <a
            href="#"
            class="table-btn"
            @click="deleteFrontendClient(frontendClient.id)"
          >
            Delete
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue, Options } from "vue-class-component";
import {
  ApiEndpoint,
  CloudStorage,
  FrontendClientResponse,
  OauthClient,
} from "../../types/index";

@Options({
  // Define component options here
  // props: {
  //   ...
  // },
})
class FrontendClientComponent extends Vue {
  frontendClients: FrontendClientResponse[] = [];
  loading: boolean = true;
  error: string = "";
  created() {
    this.getFrontendClients().then(() => {
      this.loading = false;
    });
  }

  formatApiEndpointDomain(apiEndpoint: ApiEndpoint): string {
    return apiEndpoint?.domain ?? "";
  }

  formatOauthClientIdAndSecret(oauthClient: OauthClient): string {
    if (oauthClient?.client_id && oauthClient?.secret) {
      return `id: ${oauthClient.client_id} & secret: ${oauthClient.secret}`;
    } else {
      return "";
    }
  }

  formatCloudStoragePath(cloudStorage: CloudStorage): string {
    if (cloudStorage?.path && cloudStorage?.prefix && cloudStorage?.version) {
      return `${cloudStorage.path}${cloudStorage.prefix}${cloudStorage.version}`;
    } else {
      return "";
    }
  }

  async getFrontendClients() {
    try {
      const response: AxiosResponse<{ data: FrontendClientResponse[] }> =
        await apiFetch.frontendClient.getFrontendClientsList();
      this.frontendClients = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async deleteFrontendClient(id: number) {
    try {
      await apiFetch.frontendClient.deleteFrontendClient(id);
      await this.getFrontendClients();
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default FrontendClientComponent;
</script>
